import React from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Publications } from "../components/Publications";
import erc from "../static/images/ERC.png";
import ripe from "../static/images/RIPE.png";

const ResearchPage = () => {
  return (
<html lang='en'>

<head>
  <meta charSet="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="refresh" content="0; URL=https://www.thousandeyes.com/research/" />
  <title>Research | Code BGP</title>
</head>

<body>
</body>

</html>
)
}

export default ResearchPage
